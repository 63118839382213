import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set) =>
{
    return {
        blocksCount: 3,
        blocksSeed: 0,
        
        phase: 'ready',
        sphereClick: 0,
        
        restart: (val) =>
        {
            set((state) =>
            {
                
                if ( val === 'bt1' ) {
                    return { phase: val, blocksSeed: Math.random() }
                }else if ( val === 'bt2' ) {
                    return { phase: val, blocksSeed: Math.random() }
                }else if ( val === 'bt3' ) {
                    return { phase: val, blocksSeed: Math.random() }
                }else if ( val === 'bt4' ) {
                    return { phase: val, blocksSeed: Math.random() }
                }else {
                    return { phase: 'bt4', blocksSeed: Math.random() }
                }
                
                return {}
            })
        },

        valColor: '#FFFFFF',

        newBackgroundColor: (val) =>
        {
            set((state) => 
            {
                console.log("store", val);
                return { valColor: val}
            })
        },

        clickSphere: (val) =>
        {
        set((state) =>
        {
            console.log("clickSphere", val)
            return { sphereClick: val, blocksSeed: Math.random() }
            //   if ( val === 'bt1' ) {
        // return { phase: val, blocksSeed: Math.random() }
            //   }else if ( val === 'bt2' ) {
        // return { phase: val, blocksSeed: Math.random() }
            //   }else if ( val === 'bt3' ) {
        // return { phase: val, blocksSeed: Math.random() }
            //   }else if ( val === 'bt4' ) {
        // return { phase: val, blocksSeed: Math.random() }
            //   }else {
        // return { phase: 'bt4', blocksSeed: Math.random() }
            //   }

              return {}
        })
        },
        

    }
}))