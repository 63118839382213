import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import { Suspense } from 'react'
import Experience from './Experience.jsx'
import Interface from './Interface.jsx'
import Loading from './Loading.jsx'

const App = () => {
    
    return <>
        
            <Interface />
        
            <Canvas
                camera={ {
                    fov: 45,
                    near: 0.1,
                    far: 200,
                    position: [ 0, 0, 6 ]
                } }
            >
                    <Suspense fallback={<Loading />}>           
                        <Experience />
                    </Suspense>
            </Canvas>
        
        <div id="myLoader">
            
            <div className="preloading">
                <div className="spinner"></div>
            </div>
            
        </div>

</>
}

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(<App />);

