import { Sphere, MeshDistortMaterial, Sparkles, Environment, useCursor, QuadraticBezierLine} from '@react-three/drei'
// import { OrbitControls, Text3D, Html, Center, Float, Stage, Sphere, MeshDistortMaterial, Sparkles, Environment, GradientTexture, useCursor, Line, QuadraticBezierLine} from '@react-three/drei'
import { Perf } from 'r3f-perf'
import * as THREE from 'three'
// import { EffectComposer, Vignette, Bloom, DepthOfField, Glitch } from '@react-three/postprocessing'
// import { RigidBody, Physics } from '@react-three/rapier'
import { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { useFrame, useThree, useLoader } from '@react-three/fiber'
// import { useControls } from 'leva'
import useStore from './stores/useStore.jsx'
import { gsap, Power4 } from "gsap";
import { TextureLoader } from 'three/src/loaders/TextureLoader'


let oldPositionX = 0
let oldPositionY = 0
let changeMenu = 'bt0'
let openContent = false
let countClick = 1
    
export default function Experience( )
{
    const mySphere = useRef()
    const menu1 = useRef()
    // const { blobSpeed } = useControls({ blobSpeed: { value: 2, min: 0, max: 10, step: 1 }, })
    // const { blobDistord } = useControls({ blobDistord: { value: 0.5, min: -10, max: 10, step: 0.1 }, })
    // const { blobMetalness } = useControls({ blobMetalness: { value: 0.5, min: 0, max: 1, step: 0.1 }, })
    // const { blobRoughness } = useControls({ blobRoughness: { value: 0.7, min: 0, max: 1, step: 0.1 }, })
    // const { blobRadius } = useControls({ blobRadius: { value: 1, min: 0, max: 5, step: 0.1 }, })
    
    const blobRoughness = 0.7
    const [ baseSpeed, setBaseSpeed ] = useState(2)
    const [ baseDistord, setBaseDistord ] = useState(0.5)
    const [ radius, setRadius ] = useState(1) // blobRadius
    const [ blobColor, setBlobColor ] = useState('#2e6608') // blobRadius // #0b792b
    const [ blobMetal, setBlobMetal ] = useState(0.5) // blobRadius // #0b792b
    const [ clickedSphere, setClickedSphere ] = useState(false) // blobRadius // #0b792b
    const [ clickSpherePos_x, setClickSpherePos_x ] = useState(false) // blobRadius // #0b792b
    const [ clickSpherePos_y, setClickSpherePos_y ] = useState(false) // blobRadius // #0b792b


    // let tabRotation = 0
    const [ tabRotation, setTabRotation ] = useState(0)
    useEffect(() => {

        
        // console.log("experience first time" )
        const preloader = document.querySelector('.preloading')
        const preloaderContener = document.querySelector('#myLoader')
        

        gsap.to( preloader, { opacity: 0, delay: 0.0, duration: 0.6, onComplete: () => {
            
            preloader.style.display = "none"

        } })

        
        gsap.to( preloaderContener, { opacity: 0, delay: 0.4, duration: 1.4, onComplete: () => {
            
            preloaderContener.style.display = "none"
            
        } })

        const interfaceLogo = document.querySelector('.logo')
        const interfaceCreative = document.querySelector('.creativeDev')
        const interfaceMenu = document.querySelector('#menuBts')
        const interfaceNext = document.querySelector('#btSuivant')
        const interfaceColorCircle = document.querySelector('.btColorChoice')
        const interfaceColorTxt = document.querySelector('.mentions')

        gsap.fromTo( interfaceLogo, {opacity: 0, y: 10}, {opacity: 1, y: 0, delay: 0.8, duration: 0.4} )
        gsap.fromTo( interfaceCreative, {opacity: 0, y: -10}, {opacity: 1, y: 0, delay: 1.2, duration: 0.2} )
        gsap.fromTo( interfaceMenu, {opacity: 0, y: 10}, {opacity: 1, y: 0, delay: 1.2, duration: 0.2} )
        gsap.fromTo( interfaceNext, {opacity: 0, y: -10}, {opacity: 1, y: 0, delay: 1.2, duration: 0.2} )
        gsap.fromTo( interfaceColorCircle, {opacity: 0, y: -10}, {opacity: 1, y: 0, delay: 1.2, duration: 0.2} )
        gsap.fromTo( interfaceColorTxt, {opacity: 0, y: -10}, {opacity: 1, y: 0, delay: 1.2, duration: 0.2} )

        setTabRotation(Math.random() * ( Math.PI / 2 ))
        

        const unsubscribeColor = useStore.subscribe(
            (state) => {
                return state.valColor
            },
            (value) => {
                setBlobColor(value)
            }
        )

        const unsubscribeReset = useStore.subscribe(
            (state) => {
                return state.phase
            },
            (value) => {
                if ( value === 'bt1' ) {
                    changeMenu = 'bt1'
                }else if ( value === 'bt2' ) {
                    changeMenu = 'bt2'
                }else if ( value === 'bt3' ) {
                    changeMenu = 'bt3'
                }else if ( value === 'bt4' ) {
                    changeMenu = 'bt4'
                }
            }
        )

        return () => {
            unsubscribeReset()
            unsubscribeColor()
        }

        
        

    }, [])
    


    
    function sqr(a) {
        return a*a;
    }

    
    function Rig() {
        const { mouse } = useThree()
        let incSpeed = 0
        return useFrame(() => {

            if ( openContent ) {

                mySphere.current.position.x = 0
                mySphere.current.position.y = 0
                setBaseSpeed( 0.5 )
                setBaseDistord( 0.5 )

            }else{
                /**
                 * Position de la sphere
                 */
                mySphere.current.position.x -= (mySphere.current.position.x - (mouse.x * 1) ) / 200
                mySphere.current.position.y -= (mySphere.current.position.y - (mouse.y * 1) ) / 200
                
                if ( mySphere.current.position.x > 0.6 ) {
                    mySphere.current.position.x = 0.6
                }
                if ( mySphere.current.position.x < -0.6 ) {
                    mySphere.current.position.x = -0.6
                }
                if ( mySphere.current.position.y > 0.6 ) {
                    mySphere.current.position.y = 0.6
                }
                if ( mySphere.current.position.y < -0.6 ) {
                    mySphere.current.position.y = -0.6
                }

                /**
                 * Blob speed & distord
                 */
                let d = Math.sqrt(sqr(oldPositionY - mouse.y) + sqr(oldPositionX - mouse.x));
                
                oldPositionX = mouse.x
                oldPositionY = mouse.y 

                
                let perturbation = d*10 
                if ( perturbation > incSpeed ) {
                    incSpeed += ( perturbation * 5 / 10 )
                }
                
                if ( incSpeed > 0.2 ) {
                    incSpeed -= 0.0000001 
                }else{
                    incSpeed = 0
                }

                
                setBaseSpeed( 2 + (incSpeed/1000) )
                setBaseDistord( 0.5 + (incSpeed/100) )
            }
            
            
            
            

        })
    }

    const clickSphere = useStore((state) => state.clickSphere )

    // const clickMoveSphere = (e) => {
        // 
        // if ( clickedSphere ) {
            // const d = Math.sqrt(sqr(clickSpherePos_y - e.offsetY) + sqr(clickSpherePos_x - e.offsetX));
            // setBlobMetal( 0.5 - ( -d / 1000 ) )
        // }
    // }
    const clickUpSphere = (e) => {
        setClickedSphere(false)
        setRadius ( 1 ) 
        
    }
    const clickDownSphere = (e) => {
        
        e.stopPropagation()
        console.log("changeMenu", openContent)

        if ( !openContent ) {
            // on change de menu
            clickSphere(countClick++)
        }

        setClickedSphere(true)
           
        setRadius ( 0.95 ) 
        
        setClickSpherePos_x ( e.offsetX )
        setClickSpherePos_y ( e.offsetY )
        
        
    }

    
    const Satellite = ({ distance, speed, rot }) => {
        const satelliteRef = useRef();
        useFrame((state, delta) => {
        const time = state.clock.elapsedTime;
        if (satelliteRef.current) {
            const angle = time * speed;
            const x = distance * Math.cos(angle);
            const z = distance * Math.sin(angle);
            satelliteRef.current.position.set(x, 0, z);
        }
        });
    
        return (
            <group rotation-z={rot}>
                <mesh ref={satelliteRef} >
                    <sphereGeometry args={[0.02, 8, 8]} scale={1}/>
                    <meshStandardMaterial color="#c3c3c3" />
                </mesh>
            </group>
        );
    };

    const displayMenu = (val) => {

        const btn1 = document.getElementById('bt1')
        const btn2 = document.getElementById('bt2')
        const btn3 = document.getElementById('bt3')
        
        const aboutContent = document.getElementsByClassName("contentAbout")[0];
        const workContent = document.getElementsByClassName("contentWork")[0];
        const contactContent = document.getElementsByClassName("contentContact")[0];
        const myViseur = document.getElementById('viseur')

        if ( val === null ) {

            // Afficher le menu
            btn1.style.display = 'block';
            btn2.style.display = 'block';
            btn3.style.display = 'block';
            
            myViseur.style.display = 'block'
            aboutContent.style.display = 'none'
            workContent.style.display = 'none'
            contactContent.style.display = 'none'

            gsap.to( '.creativeDev', { opacity: 1, delay: 0.1, duration: 0.3 })
            gsap.to( btn3, { y: 0, opacity: 1, delay: 0.1, duration: 0.1 })
            gsap.to( btn2, { y: 0, opacity: 1, delay: 0.2, duration: 0.1 })
            gsap.to( btn1, { y: 0, opacity: 1, delay: 0.3, duration: 0.1 })
            
            // btClose.style.display = 'none';
            gsap.to( '.line-TopRight', { height: 90, y: 0, delay: 0.0, duration: 0.3 })
            // gsap.to( '#bt4', { height: 104, y: 0, delay: 0.0, duration: 0.3 })
            

        }else  if ( val === 'bt1' ) {
            
            // Cacher le menu
            gsap.to( '.creativeDev', { opacity: 0, delay: 0.1, duration: 0.3 })
                
                myViseur.style.display = 'none'
                workContent.style.display = 'none'
                aboutContent.style.display = 'block'
                contactContent.style.display = 'none'

                gsap.fromTo( '#titreAbout', { y: -70, opacity: 1 }, { y: -10, opacity: 1, delay: 0.0, duration: 0.4 })
                gsap.fromTo( '#infoAbout', { y: -30, opacity: 0 }, { y: -10,  opacity: 1, delay: 0.45, duration: 0.3 })

                const childDivs = document.getElementById('menuAboutBts').getElementsByTagName('div');
                let temp = 0.4

                for( let i=0; i< childDivs.length; i++ )
                {
                    const title = childDivs[i];
                    let sizeTxt = 20
                    gsap.fromTo( title, {fontSize: 1, y: 20},
                        { fontSize: sizeTxt, y: 0, delay: temp + i/10, autoRound: false, duration: 0.2, ease: Power4.easeOut })
                }
                

        }else  if ( val === 'bt2' ) {
            
            // Cacher le menu
            gsap.to( '.creativeDev', { opacity: 0, delay: 0.1, duration: 0.3 })
                
                myViseur.style.display = 'none'
                aboutContent.style.display = 'none'
                workContent.style.display = 'block'
                contactContent.style.display = 'none'

                gsap.fromTo( '#titreWork', { y: -70, opacity: 1}, { y: -10, opacity: 1, delay: 0.0, duration: 0.4 })
        

        }else  if ( val === 'bt3' ) {
            
            gsap.to( '.creativeDev', { opacity: 0, delay: 0.1, duration: 0.3 })
            gsap.to( btn2, { x: 0, opacity: 1, delay: 0.2, duration: 0.1, onComplete: () => {

                myViseur.style.display = 'none'
                aboutContent.style.display = 'none'
                workContent.style.display = 'none'
                contactContent.style.display = 'block'
                
                gsap.fromTo( '#titreContact', { y: -70, opacity: 1 }, { y: -10, opacity: 1, delay: 0.0, duration: 0.4 })
                
                gsap.fromTo( '#infoContactPartn1', { y: -30, opacity: 0 }, { y: -10,  opacity: 1, delay: 0.45, duration: 0.3 })
                gsap.fromTo( '#infoContactPartn2', { y: -30, opacity: 0 }, { y: -10,  opacity: 1, delay: 0.55, duration: 0.3 })

            } })
        }
        
    }

    useFrame((state, delta) => {

        
        if ( changeMenu === "bt1" ) {

            displayMenu(changeMenu)
            openContent = true
            changeMenu = 'bt0'

            gsap.to( state.camera.position, { x: -1, y: -1, duration: 0.6 })
            
            gsap.to( state.camera, {
                duration: 0.45,
                zoom: 3,
                onUpdate: function () {
                
                    state.camera.updateProjectionMatrix();
                
                }
            } );

        }else if ( changeMenu === "bt2" ) {

            openContent = true
            displayMenu(changeMenu)
            changeMenu = 'bt0'

            gsap.to( state.camera.position, { x: -1, y: -1, duration: 0.6 })
            
            gsap.to( state.camera, {
                duration: 0.45,
                zoom: 3,
                onUpdate: function () {
                
                    state.camera.updateProjectionMatrix();
                
                }
            } );
            
            


        }else if ( changeMenu === "bt3" ) {

            openContent = true
            displayMenu(changeMenu)
            changeMenu = 'bt0'
            gsap.to( state.camera.position, { x: -1, y: -1, duration: 0.6, ease: Power4.easIn })
            gsap.to( state.camera, {
                duration: 0.45,
                zoom: 3,
                onUpdate: function () {
                
                    state.camera.updateProjectionMatrix();
                
                }
            } );

        }else if ( changeMenu === "bt4" ) {
            openContent = false
            displayMenu(null)
            changeMenu = 'bt0'
            gsap.to( state.camera.position, { x: 0, y: 0, duration: 0.5, ease: Power4.easOut })
            gsap.to( state.camera, {
                duration: 0.5,
                zoom: 1,
                onUpdate: function () {
                
                    state.camera.updateProjectionMatrix();
                
                }
            } );
        }

        
    })

    
    return <>

        <color args={ [ blobColor ] } attach="background" />
        
        <ambientLight intensity={ 0.5 } />
        <directionalLight position={ [1, 2, 3]} castShadow intensity={ 1.5 } />
        

        <Sphere ref={mySphere} visible args={ [1, 200, 300]} position-x={ 0 } scale={ 1 } 
            onPointerDown={clickDownSphere}
            onPointerUp={clickUpSphere}
            // onPointerMove={clickMoveSphere}
            onPointerMissed={clickUpSphere} 
        >
            <MeshDistortMaterial color={blobColor} distort={baseDistord} speed={baseSpeed} radius={radius} metalness={blobMetal} roughness={blobRoughness} toneMapped={true}  />
            <Sparkles count={20} scale={1 * 2} size={12} speed={0.9} opacity={0.5} />
        </Sphere>

        <Rig />
        
        <Satellite distance={1.4} speed={0.4} rot={Math.PI / 2} />
        <Satellite distance={-1.6} speed={0.3} rot={Math.PI / 3} />
        <Satellite distance={1.8} speed={0.2} rot={Math.PI / 8} />

        <Environment preset="studio" />

            
    </>
}