import { gsap, Power4, Circ } from "gsap";
import { useEffect } from 'react'
import useStore from './stores/useStore.jsx'

export default function Interface() {

    const creativeArray = ['CREATIVE', 'INTERACTIF', 'WEBSITE', 'ANIMATION', 'APPLICATION', 'WEBAPP', 'E-COMMERCE', 'WEBGL', 'FRONTEND']
    let creativeNb = 1
    // const selectedColor = '#333333'
    const selectedColor = '#484848'
    // const selectedColor = '#0b792b'
    const restart = useStore((state) => state.restart )
    let menuSlt = null
    let workCategory = "workTitlen4"
    let aboutCategory = "aboutBt1"

    const imgsGalery = [
                {
                    "tag": "Webapp",
                    // "url": "./assets/realisation003.jpg",
                    "url": "./assets/realisation062.jpg",
                    "titre": "Application web 2D/3D",
                    "info": "Logiciel d'aménagement 2d/3D en ligne. Meublez, décorez, personnalisez et chiffrez vos intérieurs.",
                    "link": "https://my.3dplanner.app/",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation055.jpg",
                    "titre": "Site institutionnel",
                    "info": "Développement front-end et animations",
                },
                {
                    "tag": "Webapp",
                    "url": "./assets/realisation063.jpg",
                    "titre": "application web 3D",
                    "info": "Configurateur de douche sur mesure",
                    "link": "https://www.showerandco.com/",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation064.jpg",
                    "titre": "Site institutionnel",
                    "info": "Présentation des projets réalisés sur une map monde 3d",
                    "link": "https://www.mfi.fr/",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation065.jpg",
                    "titre": "Site institutionnel",
                    "info": "Représentation d'un univers d'objets 3d",
                },
                {
                    "tag": "Webapp",
                    "url": "./assets/realisation002.jpg",
                    "titre": "Application web 2D/3D",
                    "info": "Configurateur de dressing sur mesure et agencement intérieur",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation056.jpg",
                    "titre": "Site e-commerce",
                    "info": "Site de vente d'enveloppe personnalisée",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation057.jpg",
                    "titre": "Site institutionnel",
                    "info": "Site du groupe et déclinaison des marques",
                },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation058.jpg",
                    "titre": "Jeu concours",
                    "info": "Configurateur de doudoune",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation052.jpg",
                    "titre": "Site e-commerce",
                    "info": "Site de vente de livres en ligne",
                },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation059.jpg",
                    "titre": "Jeu quizz",
                    "info": "Jeu autour des célébrités et de l'handicap",
                },
                // {
                    // "tag": "Site",
                    // "url": "./assets/realisation006.jpg",
                    // "titre": "Dan Jukes",
                    // "info": "Dan Jukes",
                // },
                // {
                    // "tag": "Site",
                    // "url": "./assets/realisation008.jpg",
                    // "titre": "Support de formation",
                    // "info": "Développement d'un support de formation",
                // },
                {
                    "tag": "Site",
                    "url": "./assets/realisation012.jpg",
                    "titre": "Site formation",
                    "info": "Suupport de formation interne",
                },
                // {
                    // "tag": "Site",
                    // "url": "./assets/realisation015.jpg",
                    // "titre": "Jeu",
                    // "info": "Développement module création d'avatar",
                // },
                {
                    "tag": "Site",
                    "url": "./assets/realisation017.jpg",
                    "titre": "Site formation",
                    "info": "Module de formation interne",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation019.jpg",
                    "titre": "Site formation",
                    "info": "Module de formation interne",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation020.jpg",
                    "titre": "Site formation",
                    "info": "Module de formation interne",
                },
                // {
                    // "tag": "Site",
                    // "url": "./assets/realisation021.jpg",
                    // "titre": "Site évenementiel",
                    // "info": "Site front-end du festival",
                // },
                {
                    "tag": "Site",
                    "url": "./assets/realisation022.jpg",
                    "titre": "Site évenementiel",
                    "info": "Site front-end d'un festival",
                },
                // {
                    // "tag": "Site",
                    // "url": "./assets/realisation024.jpg",
                    // "titre": "Dan Jukes",
                    // "info": "Dan Jukes",
                // },
                // A VOIR
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation025.jpg",
                    // "titre": "Dan Jukes",
                    // "info": "Dan Jukes",
                // },
                //{
                    // "tag": "Jeu",
                    // "url": "./assets/realisation026.jpg",
                    // "titre": "Jeu concours",
                    // "info": "Outil pour le récrutement",
                // },
                {
                    "tag": "Site",
                    "url": "./assets/realisation028.jpg",
                    "titre": "Site immobilier",
                    "info": "Site pour un programme immobilier",
                },
                {
                    "tag": "Webapp",
                    "url": "./assets/realisation029.jpg",
                    "titre": "application web",
                    "info": "Calculateur assurance",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation030.jpg",
                    "titre": "Site formation",
                    "info": "Module de formation interne",
                },
                // {
                    // "tag": "Site",
                    // "url": "./assets/realisation031.jpg",
                    // "titre": "Site immobilier",
                    // "info": "Site promotion immobilière",
                // },
                {
                    "tag": "Site",
                    "url": "./assets/realisation032.jpg",
                    "titre": "Site immobilier",
                    "info": "Site promotion immobilière",
                },
                {
                    "tag": "Webapp",
                    "url": "./assets/realisation033.jpg",
                    "titre": "application web",
                    "info": "Calculateur assurance",
                },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation034.jpg",
                    "titre": "Jeu concours",
                    "info": "Jeu type guitar heroe",
                },
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation035.jpg",
                    // "titre": "Jeux",
                    // "info": "Jeu pour dirigerun caddie dans les rayon d'un magasin et jeu de casse brique",
                // },
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation037.jpg",
                    // "titre": "Jeu concours",
                    // "info": "Jeu des régions",
                // },
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation039.jpg",
                    // "titre": "Jeu puzzle",
                    // "info": "Dan Jukes",
                // },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation041.jpg",
                    "titre": "Jeu concours",
                    "info": "Jeu de grattage",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation042.jpg",
                    "titre": "Site institutionnel",
                    "info": "Site d'une agence olfactive",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation043.jpg",
                    "titre": "Site formation",
                    "info": "Support de formation interne",
                },
                {
                    "tag": "Site",
                    "url": "./assets/realisation044.jpg",
                    "titre": "Site immobilier",
                    "info": "Site de présentation d'un projet immobilier",
                },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation046.jpg",
                    "titre": "Jeu Monopoly",
                    "info": "Jeu type Monopoly servant aussi à la formation",
                },
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation047.jpg",
                    // "titre": "Jeu fomation",
                    // "info": "Jeu type Monopoly servant aussi à la formation en interne",
                // },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation048.jpg",
                    "titre": "Jeu concours",
                    "info": "Jeu de drag-and-drop",
                },
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation049.jpg",
                    // "titre": "Jeu en ligne",
                    // "info": "Repmpir les bouteilles de lait",
                // },
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation050.jpg",
                    // "titre": "Jeu en ligne",
                    // "info": "Casse briques",
                // },
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation051.jpg",
                    // "titre": "Jeu en ligne",
                    // "info": "Jeu de drag and drop",
                // },         
                // {
                    // "tag": "Jeu",
                    // "url": "./assets/realisation053.jpg",
                    // "titre": "Jeu concours",
                    // "info": "Jeu de tir handball",
                // },
                
                  
                // {
                    // "tag": "Webapp",
                    // "url": "./assets/realisation054.jpg",
                    // "titre": "Jeu Noel",
                    // "info": "Dan Jukes",
                // },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation016.jpg",
                    "titre": "Jeu concours",
                    "info": "Module pour la création d'un avatar",
                },
                {
                    "tag": "Jeu",
                    "url": "./assets/realisation001.jpg",
                    "titre": "Jeu concours",
                    "info": "Jeu sur l'innovation et la soumission d'idées",
                },
                // {
                    // "tag": "Site",
                    // "url": "./assets/realisation060.jpg",
                    // "titre": "Univers 3D",
                    // "info": "Représentation d'un unviers 3D",
                // },
                
            ]
            
    
    let categoriesArray = []
    useEffect(() => {

        const state = useStore.getState()

        const txt1 = document.getElementById('textMasque1')
        const txt2 = document.getElementById('textMasque2')
            
        txt1.innerHTML = creativeArray[0]
        txt2.innerHTML = creativeArray[1]

        if ( creativeArray[0] === undefined ) {
            txt1.innerHTML = "CREATIVE"
            txt2.innerHTML = "INTERACTIF"
        }

        const timer = setInterval(() => {

            let cNb3 = creativeNb - 1
            if ( cNb3 < 0 ) cNb3 = 0
            txt1.innerHTML = creativeArray[cNb3]
            txt2.innerHTML = creativeArray[creativeNb]
            
            if ( creativeArray[cNb3] === undefined || creativeArray[creativeNb] === undefined) {
                txt1.innerHTML = "CREATIVE"
                txt2.innerHTML = "INTERACTIF"
            }

            // Code d'animation GSAP
            gsap.fromTo(txt1, {
                y: -6,
                duration: 0.5/* durée de l'animation */,
                ease: Power4.easeOut,
            },
            {
                y: 34/* valeur de déplacement en pixels */,
                
            });

            gsap.fromTo(txt2, {
                y: -40,
                duration: 0.5/* durée de l'animation */,
                ease: Power4.easeOut,
            onComplete: () => {
                creativeNb += 0.5
                let cNb2 = creativeNb + 1
                if ( creativeNb >= creativeArray.length ) creativeNb = 0
                if ( cNb2 >= creativeArray.length ) cNb2 = 0

            },
            }, {
            y: -6/* valeur de déplacement en pixels */,
            
            });
        }, 2000); // 2000 millisecondes (2 secondes)




        const usubscribeCliclSphere = useStore.subscribe(
            (state) => {
                return state.sphereClick
            },
            (value) => {
                console.log("sphereClick", value)
                // setBlobColor(value)
                clickBtSuivant()
            }
        )

        /**
         * récupérer les tag dans le json pour creer les catégories
         */
        // categoriesArray = extractUniqueTags(imgsGalery);
        // console.log(categoriesArray);

        window.addEventListener('resize', handleResize);
        
        return () => {
            clearInterval(timer); // Nettoie le timer lorsque le composant est démonté
            window.removeEventListener('resize', handleResize);
            usubscribeCliclSphere()
        };



    }, []);

    const handleResize = () => {

        const introTxt = document.getElementById('introAbout')
        const introTxtBt = document.getElementById('introAboutBt')
        
        const introTxtN1 = document.getElementById('subMenuAboutn1')
        const introTxtN2 = document.getElementById('subMenuAboutn2')
        const introTxtN3 = document.getElementById('subMenuAboutn3')

        const introTxtBt1 = document.getElementById('aboutBt1')
        const introTxtBt2 = document.getElementById('aboutBt2')
        const introTxtBt3 = document.getElementById('aboutBt3')

        const w = window.innerWidth;
        const h = window.innerHeight;

        if ( w < 500 || h < 500 ) {

            gsap.to( introTxt, { opacity: 1, duration: 0.1 })
            
            introTxt.style.display = 'block'
            introTxtBt.style.display = 'none'

            introTxtN1.style.opacity = 0
            introTxtN2.style.opacity = 0
            introTxtN3.style.opacity = 0
            
            gsap.to( introTxtBt1, { color: "#FFFFFF", duration: 0.1 })
            gsap.to( introTxtBt2, { color: "#FFFFFF", duration: 0.1 })
            gsap.to( introTxtBt3, { color: "#FFFFFF", duration: 0.1 })
        }
    };

    function workImgPlusClick () {
        console.log("workImgPlusClick")
    }

    function extractUniqueTags(imgsGalery) {
        const uniqueTags = [];
        
        imgsGalery.forEach(img => {
            if (!uniqueTags.includes(img.tag)) {
                uniqueTags.push(img.tag);
            }
        });
        
        return uniqueTags;
    }

    function extractImageTags(valToFind) {

        imgsGalery.forEach(img => {
            if (img.tag === valToFind || valToFind === 'Tous' ) {



                const div = document.createElement("div");
                div.className = "workImg";
                
                const imgElement = document.createElement("img");
                imgElement.src = img.url;
                // imgElement.width = "100%";
                imgElement.style.width = "100%"; 
                imgElement.alt = "realisation";
                div.appendChild(imgElement);
                
                const textDivTitre = document.createElement("div");
                textDivTitre.className = "workImgTitre";
                textDivTitre.innerText = img.titre;
                div.appendChild(textDivTitre);

                const textDiv = document.createElement("div");
                textDiv.className = "workImgText";
                textDiv.innerText = img.info;
                div.appendChild(textDiv);
                
                const textDivPlus = document.createElement("div");
                textDivPlus.className = "workImgPlus";
                if ( img.link ) {

                    var createA = document.createElement('a');
                    var createAText = document.createTextNode("VISITER");
                    createA.setAttribute('href', img.link);
                    createA.setAttribute('target', "_blank");
                    createA.appendChild(createAText);
                    textDivPlus.appendChild(createA);

                }else{

                    const button = document.createElement("div");

                    // Définition des attributs du bouton
                    button.textContent = "+ D'INFO NOUS CONTACTER";

                    // Ajout d'un gestionnaire d'événement onClick
                    button.addEventListener("click", () => {
                        console.log("yahou")
                        // alert("Le bouton a été cliqué !");
                        clickMenu()
                    });
                    textDivPlus.appendChild(button);

                }
                
                div.appendChild(textDivPlus);

                // Maintenant, vous pouvez ajouter la div créée à l'endroit souhaité dans votre document HTML
                // Supposons que 'container' soit l'élément HTML où vous voulez ajouter vos divs
                const container = document.getElementById("infoWork");
                container.appendChild(div);
                
            }
        });
        
        // return uniqueTags;
    }
    
    function overAboutMenu (e) {
        const childDivs = document.getElementById('menuAboutBts').getElementsByTagName('div');

        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            if ( title.id !== aboutCategory ) {
                // console.log("clickMenu", title.id)
                if ( title.id === e.target.id ) {
                    gsap.to( title, { fontSize: 25, delay: 0, autoRound: false, duration: 1.3, ease: Power4.easeOut })
                }else{
                    gsap.to( title, { fontSize: 20, delay: 0, autoRound: false, duration: 1.3, ease: Power4.easeOut })
                }
            }

        }

    }

    function outAboutMenu (e) {
        const childDivs = document.getElementById('menuAboutBts').getElementsByTagName('div');

        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            if ( title.id !== aboutCategory ) {
                gsap.to( title, { fontSize: 20, delay: 0, autoRound: false, duration: 1.3, ease: Power4.easeOut })
            }

        }
    }
    function clickIntroBt (e) {

        const w = window.innerWidth;
        const h = window.innerHeight;

        const introTxt = document.getElementById('introAbout')
        const introTxtBt = document.getElementById('introAboutBt')
        const introTxtN1 = document.getElementById('subMenuAboutn1')
        const introTxtN2 = document.getElementById('subMenuAboutn2')
        const introTxtN3 = document.getElementById('subMenuAboutn3')

        const introTxtBt1 = document.getElementById('aboutBt1')
        const introTxtBt2 = document.getElementById('aboutBt2')
        const introTxtBt3 = document.getElementById('aboutBt3')

        if ( w < 500 || h < 500 ) {
            
            gsap.to( introTxt, { opacity: 1, duration: 0.1 })
            
            introTxt.style.display = 'block'
            introTxtBt.style.display = 'none'

            introTxtN1.style.opacity = 0
            introTxtN2.style.opacity = 0
            introTxtN3.style.opacity = 0
            
            gsap.to( introTxtBt1, { color: "#FFFFFF", duration: 0.1 })
            gsap.to( introTxtBt2, { color: "#FFFFFF", duration: 0.1 })
            gsap.to( introTxtBt3, { color: "#FFFFFF", duration: 0.1 })
        }

    }


    function clickAboutMenu (e) {
        
        const w = window.innerWidth;
        const h = window.innerHeight;

        const introTxt = document.getElementById('introAbout')
        const introTxtBt = document.getElementById('introAboutBt')
        
        let dec_y = 0
        if ( h < 500 ) dec_y = -50

        if ( w < 500 || h < 500 ) {    
            gsap.to( introTxt, { opacity: 0, duration: 0.1, onComplete: () => {
                introTxt.style.display = 'none'
                introTxtBt.style.display = 'block'
                
            } })

        }else{
            
            introTxt.style.display = 'block'
            introTxtBt.style.display = 'none'
        }


        const el = e.target
        // console.log(el.id);
        if ( el.id == "aboutBt1" ) {
            gsap.to( '#subMenuAboutn2', { translateY: -10 + dec_y, opacity: 0, duration: 0.3 })
            gsap.to( '#subMenuAboutn3', { translateY: -10 + dec_y, opacity: 0, duration: 0.3 })
            gsap.fromTo( '#subMenuAboutn1', { translateY: 10, opacity: 0, delay: 0.25, duration: 0.3 }, { translateY: 0 + dec_y, opacity: 1 })
        }else if ( el.id == "aboutBt2" ) {
            gsap.to( '#subMenuAboutn1', { translateY: -30 + dec_y, opacity: 0, duration: 0.3 })
            gsap.to( '#subMenuAboutn3', { translateY: -30 + dec_y, opacity: 0, duration: 0.3 })
            gsap.fromTo( '#subMenuAboutn2', { translateY: 20, opacity: 0, delay: 0.25, duration: 0.3 }, { translateY: 0 + dec_y, opacity: 1 })
        }else if ( el.id == "aboutBt3" ) {
            gsap.to( '#subMenuAboutn1', { translateY: -10 + dec_y, opacity: 0, duration: 0.3 })
            gsap.to( '#subMenuAboutn2', { translateY: -10 + dec_y, opacity: 0, duration: 0.3 })
            gsap.fromTo( '#subMenuAboutn3', { translateY: 10, opacity: 0, delay: 0.25, duration: 0.3 }, { translateY: 0 + dec_y, opacity: 1 })
        }

        const childDivs = document.getElementById('menuAboutBts').getElementsByTagName('div');
        // let yPos = 0

        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            
            if ( title.id === e.target.id ) {
                gsap.to( title, { fontSize: 25, color: selectedColor,  delay: 0, autoRound: false, duration: 0.2, ease: Power4.easeOut })
                aboutCategory = title.id
            }else{
                gsap.to( title, { fontSize: 20, color: "#FFFFFF",  delay: 0, autoRound: false, duration: 0.6, ease: Power4.easeOut })
            }
        }

    }


    function overBack (val) {

        let elParent = "backWork"
        // const childDivs = document.getElementById('backArrow')

        if ( menuSlt == "bt1" )
        {
            elParent = "backAbout"
        }else if ( menuSlt == "bt2" )
        {
            elParent = "backWork"
        }else //if ( menuSlt == "bt3" )
        {
            elParent = "backContact"
        }
        const childDivs = document.querySelector("#"+elParent+" #backArrow");
        
        gsap.to( childDivs, { x: -5, opacity: 0,  delay: 0, duration: 0.2, onComplete: () => {
            gsap.fromTo( childDivs, {  x: 10, opacity: 0 }, { x: 0, opacity: 1, delay: 0.0, duration: 0.4 })
        } })
        
    }

    function outBack (e) {
        // console.log("outBack");        
        const childDivs = document.getElementById('backArrow')
        gsap.to( childDivs, { x: 0, y: 0,  delay: 0, autoRound: false, duration: 0.6, ease: Power4.easeOut })
        gsap.to( "#dotBackContentEl", { opacity: 0,  delay: 0, duration: 0.2, ease: Power4.easeOut })
    }


    function clickMenu (e) {
        
        e.preventDefault();
        
        let el = undefined//e.target
        let  el_id = "bt4"
        if ( e === undefined ) {
            el_id = "bt3"
        }else{
            el_id = e.target.id
            el = e.target
        }


        
        if ( menuSlt != el_id ) {
        menuSlt = null
        
        restart(el_id)
        
        if ( el_id === "bt2" ) {
            showWorkImgs(0.4, true)
        }
        
        const w = window.innerWidth;
        const h = window.innerHeight;
        const colorBt = document.querySelector('.btColorChoice')
        const menitionBt = document.querySelector('.mentions')

        if ( el_id === "bt1" || el_id === "bt2" || el_id === "bt3" ) {
            
            if ( w < 500 || h < 500 ) {
                colorBt.style.display = 'none'
                menitionBt.style.display = 'none'
            }else{
                colorBt.style.display = 'block'
                menitionBt.style.display = 'block'
            }
        }else{
            colorBt.style.display = 'block'
            menitionBt.style.display = 'block'
        }

        const childDivs = document.getElementById('menuBts').getElementsByTagName('div');

        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            if ( title.id === el_id ) {
                gsap.to( title, { color: selectedColor,  delay: 0, autoRound: false, duration: 1.6, ease: Power4.easeOut })
                menuSlt = title.id
            }else{
                gsap.to( title, { color: "#FFFFFF",  delay: 0, autoRound: false, duration: 1.6, ease: Power4.easeOut })
            }
        }
        }
    }

    function overMenu (e) {
        
        const el = e.target
        
        const childDivs = document.getElementById('menuBts').getElementsByTagName('div');
        
        if ( el.id !== menuSlt ) {    
            for( let i=0; i< childDivs.length; i++ )
            {
                const title = childDivs[i];
                
                if ( title.id !== menuSlt ) {
                    
                    if ( title.id === e.target.id ) {
                        gsap.to( title, { fontSize: 25, y: 0, delay: 0, autoRound: false, duration: 1.6, ease: Power4.easeOut })
                    }else{
                        gsap.to( title, { fontSize: 22, y: 2, delay: 0, autoRound: false, duration: 1.6, ease: Power4.easeOut })
                    }
                }else{
                    gsap.to( title, { fontSize: 25, y: 0, delay: 0, autoRound: false, duration: 1.6, ease: Power4.easeOut })
                }

            }
        }

    }
    
    function outMenu (e) {
        
        const el = e.target
        
        const childDivs = document.getElementById('menuBts').getElementsByTagName('div');
    
        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            
            if ( title.id !== menuSlt ) {
                gsap.to( title, { fontSize: 25, y: 0, delay: 0, autoRound: false, duration: 1.6, ease: Power4.easeOut })
            }

        }
        

    }

    function clickMenuWork (e) {

        e.preventDefault();

        const childDivs = document.getElementById('infoWorkTxt').getElementsByTagName('div');

        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            
            if ( title.id === e.target.id ) {
                gsap.to( title, { fontSize: 25, color: selectedColor,  delay: 0.1, autoRound: false, duration: 0.2, ease: Power4.easeOut })
                
                workCategory = title.id
            }else{
                gsap.to( title, { fontSize: 20, y: 0, color: "#FFFFFF",  delay: 0.1, autoRound: false, duration: 0.6, ease: Power4.easeOut })
            }
            
        }
        
        hideWorkImgs()
        
        gsap.to( '#infoWork', { opacity: 0, y: -40, delay: 0.3, duration: 0.4, ease: Power4.easeOut, onComplete: () => {
            showWorkImgs(0.1, false)
        } })
        
    }

    function displaySelectedImages () {

        let filter = "site"
        if ( workCategory === "workTitlen1" ) {
            filter = "Webapp"
        }else if ( workCategory === "workTitlen2" ) {
            filter = "Jeu"
        }else if ( workCategory === "workTitlen3" ) {
            filter = "Site"
        }else if ( workCategory === "workTitlen4" ) {
            filter = "Tous"
        }
        extractImageTags(filter)

    }

    function showWorkImgs (temp, diplayMenu) {
        
        var infoWorkElement = document.getElementById("infoWork");
        while (infoWorkElement.firstChild) {
            infoWorkElement.removeChild(infoWorkElement.firstChild);
        }

        displaySelectedImages()
        
        gsap.fromTo( '#infoWork', { opacity: 0, y: 10.0}, { opacity: 1, y: 0.0, delay: 0.0, duration: 0.2, ease: Power4.easeOut })
        const childImgs = document.getElementById('infoWork').getElementsByTagName('div');
        for( let j=0; j< childImgs.length; j++ )
        {
            const myImg = childImgs[j];
            gsap.fromTo( myImg, { opacity: 0, y: -20}, 
            { opacity: 1, y: 0, delay: temp + (j/30), duration: 0.15, ease: Power4.easeOut } )
        }

        if ( diplayMenu ) {
            const childDivs = document.getElementById('infoWorkTxt').getElementsByTagName('div');
            
            for( let i=0; i< childDivs.length; i++ )
            {
                const title = childDivs[i];
                let sizeTxt = 20
                if ( title.id === workCategory ) {
                    sizeTxt = 25
                }
                gsap.fromTo( title, {fontSize: 1, y: 20},
                    { fontSize: sizeTxt, y: 0, delay: temp + i/10, autoRound: false, duration: 0.2, ease: Power4.easeOut })
            }
        }
    }

    function hideWorkImgs () {
        const childImgs = document.getElementById('infoWork').getElementsByTagName('div');
        for( let j=0; j< childImgs.length; j++ )
        {
            const myImg = childImgs[j];
            gsap.to( myImg, { opacity: 0, y: -20, delay: 0.2, duration: 0.4, ease: Power4.easeOut, onComplete: () => {
                myImg.remove()
            } })
            

        }
    }

    function overMenuWork (e) {

        const childDivs = document.getElementById('infoWorkTxt').getElementsByTagName('div');
        
        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            if ( title.id !== workCategory ) {
                if ( title.id === e.target.id ) {
                    gsap.to( title, { fontSize: 25, delay: 0, autoRound: false, duration: 1.3, ease: Power4.easeOut })
                }else{
                    gsap.to( title, { fontSize: 20, delay: 0, autoRound: false, duration: 1.3, ease: Power4.easeOut })
                }
            }

        }

    }

    function outMenuWork (e) {
        
        const childDivs = document.getElementById('infoWorkTxt').getElementsByTagName('div');

        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            
            if ( title.id !== workCategory ) {
                gsap.to( title, { fontSize: 20, delay: 0, autoRound: false, duration: 1.3, ease: Power4.easeOut })
            }

        }
    }

    function overBtSuivant (e) {

        const childDivs = document.getElementById('nextDotArrow')
        const childDivParent = document.getElementsByClassName("btSuivant");
        
        const x = e.pageX - childDivParent[0].offsetLeft
        const y = e.pageY - childDivParent[0].offsetTop
        gsap.to( "#nextDotCircle", { height: 80, width: 80, delay: 0, duration: 0.3, ease: Power4.easeOut })
        gsap.to( childDivs, { x: x - 20, y: y - 20, opacity: 1,  delay: 0, duration: 0.2, ease: Power4.easeOut })

    }
    function outBtSuivant () {
        const childDivs = document.getElementById('nextDotArrow')
        gsap.to( "#nextDotCircle", { height: 60, width: 60, delay: 0, duration: 0.4, ease: Power4.easeOut })
        gsap.to( childDivs, { x: 0, y: 0, opacity: 0.6, delay: 0, duration: 0.4, ease: Power4.easeOut })
    }
    function clickBtSuivant () {
        
        if ( menuSlt === null ) {
            menuSlt = "bt1"
            restart("bt1")
        }else if ( menuSlt === "bt1" ) {
            menuSlt = "bt2"
            showWorkImgs(0.4, true)
            restart("bt2")
        }else if ( menuSlt === "bt2" ) {
            menuSlt = "bt3"
            restart("bt3")
        }else if ( menuSlt === "bt3" ) {
            menuSlt = null
            restart("bt4")
        }

        const childDivs = document.getElementById('menuBts').getElementsByTagName('div');

        for( let i=0; i< childDivs.length; i++ )
        {
            const title = childDivs[i];
            
            if ( title.id === menuSlt ) {
                gsap.to( title, { fontSize: 25, y: 0, color: selectedColor,  delay: 0, autoRound: false, duration: 0.2, ease: Power4.easeOut })
                menuSlt = title.id
            }else{
                gsap.to( title, { fontSize: 25, y: 0, color: "#FFFFFF",  delay: 0, autoRound: false, duration: 0.6, ease: Power4.easeOut })
            }
        }

        
    }


    const newBackgroundColor = useStore((state) => state.newBackgroundColor )
    function overBtChangeColor (e) {
        const childDivsBts = document.getElementById('colorChoiceBts').getElementsByTagName('span');;
            
        for( let i=0; i< childDivsBts.length; i++ )
        {
            const title = childDivsBts[i];
            if ( title.id === e.target.id ) {
                gsap.to( title, { width: 30, height: 30, marginLeft: -2.0, delay: 0, duration: 0.4 })     
            }else{
                gsap.to( title, { width: 25, height: 25, marginLeft: 0, delay: 0, duration: 0.4 })     
            }
        }
    }
    function outBtChangeColor (e) {

        const childDivsBts = document.getElementById('colorChoiceBts').getElementsByTagName('span');;
            
        for( let i=0; i< childDivsBts.length; i++ )
        {
            const title = childDivsBts[i];
            gsap.to( title, { width: 25, height: 25, marginLeft: 0, delay: 0, duration: 0.4 })     
            
        }

    }
    const changeColor = (val) => () => {
        
        const childDivCircle = document.getElementById('nextDotColorCircle')
        
        if ( val === "color1" ) {
            childDivCircle.style.background = "#510101"
            newBackgroundColor("#510101")
        }else if ( val === "color2" ) {
            childDivCircle.style.background = "#051641"
            newBackgroundColor("#051641")
        }else if ( val === "color3" ) {
            childDivCircle.style.background = "#2e6608"
            newBackgroundColor("#2e6608")
        }else if ( val === "color4" ) {
            childDivCircle.style.background = "#000000"
            newBackgroundColor("#000000")
        }else if ( val === "color5" ) {
            childDivCircle.style.background = "#7d9238"
            newBackgroundColor("#7d9238")
        }else if ( val === "color6" ) {
            childDivCircle.style.background = "#e6c686"
            newBackgroundColor("#e6c686")
        }else if ( val === "color7" ) {
            childDivCircle.style.background = "#e686e4"
            newBackgroundColor("#e686e4")
        }
        
        const childDivs = document.getElementById('nextDotColorArrow')
        childDivs.style.opacity = 0
        childDivs.style.display = 'block'
        
        const childDivsBtsPar = document.getElementById('colorChoiceBts')
        const childDivsBts = childDivsBtsPar.getElementsByTagName('span');;
        for( let i=0; i< childDivsBts.length; i++ )
        {
            const title = childDivsBts[i];
            gsap.to( title, { marginTop: 0, opacity: 0.0, delay: 0 + i / 50, duration: 0.1, ease: Power4.easeOut })    
        }

        gsap.to( childDivs, { opacity: 1.0, delay: 0.2, duration: 0.6, ease: Power4.easeOut, onComplete: () => {
            childDivsBtsPar.style.display = 'none';
        } })    

    }



    function overBtColor (e) {
        
        const childDivs = document.getElementById('nextDotColorArrow')
        const childDivParent = document.getElementsByClassName("btColorChoice")
        
        const x = e.pageX - childDivParent[0].offsetLeft
        const y = e.pageY - childDivParent[0].offsetTop

        if ( ( x > -20 && x < 20 ) && ( y > -20+30 && y < 20+30 ) ) {
            gsap.to( "#nextDotColorCircle", { height: 40, width: 40, delay: 0, duration: 0.4, ease: Power4.easeOut })
            gsap.to( childDivs, { x: x-10, y: y-40, opacity: 1.0, delay: 0, duration: 0.4, ease: Power4.easeOut })
        }else{
            gsap.to( "#nextDotColorCircle", { height: 25, width: 25, delay: 0, duration: 0.4, ease: Power4.easeOut })
            gsap.to( childDivs, { x: 0, y: 0, opacity: 1.0, delay: 0, duration: 0.4, ease: Power4.easeOut })    
        }
    }
    function outBtColor () {
        const childDivs = document.getElementById('nextDotColorArrow')
        gsap.to( "#nextDotColorCircle", { height: 25, width: 25, delay: 0, duration: 0.4, ease: Power4.easeOut })
        gsap.to( childDivs, { x: 0, y: 0, opacity: 1.0, delay: 0, duration: 0.4, ease: Power4.easeOut })
    }
    
    function clickBtColor () {

        const childDivsBtsPar = document.getElementById('colorChoiceBts')
        childDivsBtsPar.style.display = 'flex';

        const childDivs = document.getElementById('nextDotColorArrow')
        gsap.to( "#nextDotColorCircle", { height: 0, width: 0, delay: 0, duration: 0.1, ease: Power4.easeOut })
        childDivs.style.display = 'none'

        const childDivsBts = childDivsBtsPar.getElementsByTagName('span');;
        
        for( let i=0; i< childDivsBts.length; i++ )
        {
            const title = childDivsBts[i];
            gsap.fromTo( title, { marginTop: -10, opacity: 1.0}, { marginTop: 2, opacity: 1, delay: 0 + i / 50, duration: 0.1, ease: Power4.easeOut })    
        }
        
        
    }


    return <div className="interface">
        <div id="viseur">
            +
        </div>
        <div className="line-TopLeft"></div>
        <div className="line-TopRight"></div>
        <div className="line-BottomLeft"></div>
        <div className="line-BottomRight"></div>
        <div className="logo">
            cadeLOG
        </div>

        <div className="creativeDev">
            <div id="masque">
                <span id="textMasque1"> CREATIVE</span>
                <span id="textMasque2"> CREATIVE</span>
            </div> 
            DEVELOPPEMENT
        </div>
        <div className="menu" id="menuBts">
            <div className="menuBt" id="bt1" onMouseOver={ overMenu } onMouseOut={ outMenu } onPointerDown={ clickMenu }>EXPERTISE</div>
            <div className="menuBt" id="bt2" onMouseOver={ overMenu } onMouseOut={ outMenu } onPointerDown={ clickMenu }>PROJETS</div>
            <div className="menuBt" id="bt3" onMouseOver={ overMenu } onMouseOut={ outMenu } onPointerDown={ clickMenu }>CONTACT</div>
        </div>
        

        <div className="contentAbout">
            <div id="backAbout" > 
                <img src="./assets/icon-up-arrow.svg" alt="back" width="20px" id="backArrow" />
                <span id="backBkg" onMouseOver={ overBack } onClick={ clickMenu }>

                </span>
                RETOUR 
            </div>
            <div id="masqueAbout">
                <div id="titreAbout">EXPERTISE</div>
            </div>
            <div id="infoAbout">
                <div id="introAboutBt" onPointerDown={ clickIntroBt }>CADELOG, depuis 2006,</div>
                <div id="introAbout">
                CADELOG développe des solutions web sur mesure depuis 2006. <br />Notre expertise réside dans le développement front-end et la création digitale, où chaque pixel et chaque ligne de code sont conçus pour raconter une histoire unique.
                <br />De la conception à la mise en oeuvre technique, nous vous accompagnons à chaque étape votre projet. 
                {/* Nous considérons chaque projet comme unique et mettons tout en oeuvre pour le réaliser dans les meilleures conditions. */}
                {/* créé en 2006, est un STUDIO DE CRÉATION et de DÉVELOPPEMENT WEB qui met en forme votre COMMUNICATION sur tous types de supports. */}
                {/* Nous considérons chaque projet comme unique et mettons tout en oeuvre pour le réaliser dans les meilleures conditions. */}
                </div>
                <div id="sub0MenuAbout">
                    <div className="subMenuAbout" id="menuAboutBts">
                        <div className="btMenuAbout" id="aboutBt1" onMouseOver={ overAboutMenu } onMouseOut={ outAboutMenu } onPointerDown={ clickAboutMenu }>CONCEPTION</div>
                        <div className="btMenuAbout" id="aboutBt2" onMouseOver={ overAboutMenu } onMouseOut={ outAboutMenu } onPointerDown={ clickAboutMenu }>DÉVELOPPEMENT</div>
                        <div className="btMenuAbout" id="aboutBt3" onMouseOver={ overAboutMenu } onMouseOut={ outAboutMenu } onPointerDown={ clickAboutMenu }>GRAPHISME</div>
                    </div>
                    <div className="subMenuAbout">
                        <div id="subMenuAboutn1">
                            La créativité en traduction de vos besoins.
                            <ul>
                                <li>Architecture de site web</li>
                                <li>Charte graphique</li>
                                <li>Choix des outils de développement</li>
                                <li>Conception & réalisation</li>
                                <li>Optimisation, actualisation</li>
                                <li>Référencement (SEO)</li>
                                <li>Suivi et statistiques</li>
                            </ul>
                        </div>
                        <div id="subMenuAboutn2">
                            Nous développons des outils front et back office personnalisés axés sur l'expérience utilisateur. Nous intervenons principalement dans le développement sur-mesure
                            <ul>
                                <li>de site internet</li>
                                <li>de site e-commerce</li>
                                <li>de site événementiel</li>
                                <li>de jeu interactif</li>
                                <li>d'application métier</li>
                                <li>de configurateur</li>
                                <li>d'interface 2d et/ou 3d</li>
                            </ul>
                        </div>
                        <div id="subMenuAboutn3">
                            Nous vous accompagnons à différentes étapes de votre projet.
                            <ul>
                                <li>Modélisation 3D</li>
                                <li>Création graphique 2D et 3D</li>
                                <li>Animation 2D et 3D</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </div>

        <div className="contentWork">
            <div id="backWork" > 
                <img src="./assets/icon-up-arrow.svg" alt="back" width="20px" height="20px" id="backArrow" />
                <span id="backBkg" onMouseOver={ overBack } onPointerDown={ clickMenu }>
                </span>
                <span id="backText">
                RETOUR
                </span> 
                
            </div>
            <div id="masqueWork">
                <div id="titreWork">PROJETS</div>
            </div>
            <div id="infoWorkTxt" >
                <div className="workTitle" id="workTitlen4" onMouseOver={ overMenuWork } onMouseOut={ outMenuWork } onPointerDown={ clickMenuWork } style={{color:'#484848', fontSize:'25px'}}>TOUS</div>
                <div className="workTitle" id="workTitlen1" onMouseOver={ overMenuWork } onMouseOut={ outMenuWork } onPointerDown={ clickMenuWork }>WEBAPP</div>
                <div className="workTitle" id="workTitlen3" onMouseOver={ overMenuWork } onMouseOut={ outMenuWork } onPointerDown={ clickMenuWork }>SITES</div>
                <div className="workTitle" id="workTitlen2" onMouseOver={ overMenuWork } onMouseOut={ outMenuWork } onPointerDown={ clickMenuWork }>JEUX</div>
            </div>
            <div id="infoWork" >
            </div>
            
        </div>

        <div className="contentContact">

            <div id="backContact"> 
                <img src="./assets/icon-up-arrow.svg" alt="back" width="20px" height="20px" id="backArrow" />
                <span id="backBkg" onMouseOver={ overBack } onPointerDown={ clickMenu }>

                </span>
                RETOUR 
            </div>
            <div id="masqueContact">
                <div id="titreContact">CONTACT</div>
            </div>
            <div id="infoContact">
                <div className="infoContactPart" id="infoContactPartn1">
                    <div onClick={() => window.location = 'mailto:hello@cadelog.com'}>hello@cadelog.com</div>
                    <div onClick={() => window.location = 'tel:+33185089505'}>+33 1 85 08 95 05</div>
                    
                </div>
                <div className="infoContactPart" id="infoContactPartn2">
                <div id="infoContactVille">PARIS / MONTPELLIER</div>
                <div id="infoContactTitre">CADELOG</div>
                118 rue de Vaugirard<br />
                75006 PARIS<br /><br />
                </div>
                
                
            </div>

        </div>

        <div className="btColorChoice" onMouseOver={ overBtColor } onMouseMove={ overBtColor } onMouseOut={ outBtColor } onPointerDown={ clickBtColor }>
            <div className="dotColorContent" id="nextDotColorArrow"><span className="dotColor" id="nextDotColorCircle"></span></div>
        </div>

        <div className="mentions">
            <span>CHANGEZ MOI</span>
            {/* - MENTIONS LÉGALES */}
            <div id="colorChoice">
                <div id="colorChoiceBts">
                    <span id="color1" onMouseOver={ overBtChangeColor } onMouseOut={ outBtChangeColor } onPointerDown={changeColor('color1')}></span>
                    <span id="color2" onMouseOver={ overBtChangeColor } onMouseOut={ outBtChangeColor } onPointerDown={changeColor('color2')}></span>
                    <span id="color3" onMouseOver={ overBtChangeColor } onMouseOut={ outBtChangeColor } onPointerDown={changeColor('color3')}></span>
                    <span id="color4" onMouseOver={ overBtChangeColor } onMouseOut={ outBtChangeColor } onPointerDown={changeColor('color4')}></span>
                    <span id="color5" onMouseOver={ overBtChangeColor } onMouseOut={ outBtChangeColor } onPointerDown={changeColor('color5')}></span>
                    <span id="color6" onMouseOver={ overBtChangeColor } onMouseOut={ outBtChangeColor } onPointerDown={changeColor('color6')}></span>
                    <span id="color7" onMouseOver={ overBtChangeColor } onMouseOut={ outBtChangeColor } onPointerDown={changeColor('color7')}></span>
            
                </div>
            </div>
        </div>
    
        
        <div className="btSuivant" onMouseOver={ overBtSuivant } onMouseMove={ overBtSuivant } onMouseOut={ outBtSuivant } onPointerDown={ clickBtSuivant }>
            <div className="dotContent" id="nextDotArrow"><span className="dot" id="nextDotCircle"></span></div>
            <img src="./assets/icon-up-arrow.svg" alt="back" width="30px" height="30px" id="nextArrow" />
        </div>


    </div>
}